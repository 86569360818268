import React from 'react';

import { useRouter } from 'next/router';

import css from './HomeCustomBanner.module.scss';

type BannerProps = {
    onHide: () => void;
    actionUrl: string;
    img: string;
    hideBanner: boolean;
};

const HomeCustomBanner = ({ onHide, actionUrl, hideBanner, img }: BannerProps) => {
    const router = useRouter();

    if (hideBanner) {
        return null;
    }

    return (
        <div
            onClick={() => {
                router.push(actionUrl);
            }}
            className={css.contest__banner}
            style={{ backgroundImage: `url(${img})` }}
        >
            <img
                onClick={(event) => {
                    onHide();
                    event.stopPropagation();
                }}
                className={css.contest__banner__close}
                src="/front-static/icons/navigation/close-white.svg"
                alt="close"
            />
        </div>
    );
};

export default HomeCustomBanner;
